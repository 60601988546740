@tailwind base;
@tailwind components;
/* @font-face {
    font-family: 'Dual';
    src: local('Dual'), url( ./Fonts/Dual.ttf) format('ttf');
  }
  @font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url( ./Fonts/Montserrat.ttf) format('ttf');
  } */
@tailwind utilities;

input[type="radio"] {
  cursor: pointer;
  border: 1px solid #767676;
  width: 20px;
  height: 20px;
  /* accent-color: #FF9728; */
}

.home-phone .ReactFlagsSelect-module_selectBtn__19wW7 {
  background-color: white !important;
  border-radius: 12px 0px 0px 12px !important;
  border: none !important;
  border-left: 1px solid #767676 !important;
  padding: 8px 20px !important;
  outline: none !important;
  min-width: 160px;
  max-width: 160px;
  position: absolute;
  z-index: 20;
  margin-top: 6px !important;
}
.home-phone .ReactFlagsSelect-module_selectOptions__3LNBJ {
  border: 1px solid #09c5b0 !important;
  border-radius: 5px !important;
  top: 48px;
}


.order-country .ReactFlagsSelect-module_selectBtn__19wW7 {
  background-color: white !important;
  border-radius: 16px !important;
  border: none !important;
  border: 1px solid black !important;
  padding: 6px 20px !important;
  outline: none !important;
}
.order-country .ReactFlagsSelect-module_selectOptions__3LNBJ {
  border: 1px solid #09c5b0 !important;
  border-radius: 5px !important;
  top: 40px;
}

.wall {
  background-image: url("./Assets/images/frameWall.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-attachment: scroll;
  padding: 200px 0px;
}

.blackFrame {
  /* border-image: url("./Assets/images/blackFrame.png") 593 592 587 592 stretch
    stretch; */
    border: 7px solid #000000;
}
.whiteFrame {
  /* border-image: url("./Assets/images/whiteFrame.png") 593 592 587 592 stretch
    stretch; */
    border: 7px solid #ffffff;
}
.goldFrame {
  /* border-image: url("./Assets/images/goldFrame.png") 593 592 587 592 stretch
    stretch; */
    border: 7px solid #c3a163;
}

/* src/StarRating.css */
.star-rating {
  display: flex;
  align-items: center;
  max-width: fit-content;
  position: absolute;
}

.star {
  font-size: 0;
  color: #d3d3d3;
  cursor: pointer;
  transition: color 200ms;
  display: inline-block;
  width: 44px;
  height: 40px;
}

.stars-image{
  width: 216px;
  height: 40px;
}

textarea{
  border: 1px solid #d4d4d4;
  padding: 11px 12px;
  border-radius: 4px;
  width: 100%;
  outline: none;
}

textarea:focus-visible{
 border-color:  #ff9728 !important;
}

.wallet-size{
  padding-top: 0.75rem;
  left: 0 !important;
  width: 100%;
}